<!--
 * @Author: mulingyuer
 * @Date: 2021-09-02 17:51:44
 * @LastEditTime: 2021-09-02 18:20:48
 * @LastEditors: mulingyuer
 * @Description: 设置
 * @FilePath: \saas-admin-vue\src\modules\online-classroom\views\settings.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="setting">
    <reminder title="使用须知" :text-arr="tips"></reminder>

    <div class="content">
      <!-- 授权公众号二维码 -->
      <div v-if="isAuthPublic" class="qrcode" v-loading="loading">
        <div class="code">
          <div class="text">微信公众号授权</div>
          <img :src="qrcodeurl || ''" class="img" />
          <div class="text">请使用微信公众号绑定的管理员个人微信号扫描</div>
        </div>
      </div>
      <el-form
        v-if="!isAuthPublic"
        ref="ruleForm2"
        :model="form"
        :rules="rules"
        label-width="140px"
        size="medium"
        class="medium-form"
      >
        <div class="modular">
          <h2 class="modular-title">已授权的信息</h2>
          <div class="modular-content">
            <el-form-item label="名称：">
              <div>{{ form.public_info.name }}</div>
            </el-form-item>
            <el-form-item label="头像：">
              <img class="avatar" :src="form.public_info.avatar" />
            </el-form-item>
            <el-form-item label="appid：">
              <div>{{ form.public_info.appid }}</div>
            </el-form-item>
          </div>
        </div>

        <div class="modular">
          <h2 class="modular-title">公众号二维码</h2>
          <div class="modular-content">
            <el-form-item label="弹窗提示：" prop="window_notice">
              <el-radio-group v-model="form.window_notice">
                <el-radio :label="1">开启</el-radio>
                <el-radio :label="0">关闭</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="二维码：" prop="wx_public_url">
              <ImageItem
                v-model="form.wx_public_url"
                :width="150"
                :height="120"
              />
            </el-form-item>
            <!-- <el-form-item label="提示次数：" prop="notice_limit">
              <el-radio-group v-model="form.notice_limit">
                <el-radio :label="1">仅提示一次</el-radio>
                <el-radio :label="0">重复提示</el-radio>
              </el-radio-group>
            </el-form-item> -->
          </div>
        </div>
      </el-form>

    </div>
    <FixedActionBar>
      <el-button
        v-if="!loading && !isAuthPublic"
        type="primary"
        size="medium"
        :loading="saveLoading"
        @click="onSave('ruleForm2')"
        >保存</el-button
      >
    </FixedActionBar>
  </div>
</template>

<script>
import ImageItem from '@/modules/common/components/ImageItem.vue'
import FixedActionBar from '@/base/layout/FixedActionBar'
import {
  detail,
  save,
  authQrcode,
  authStatus,
} from '@/modules/wechat-app/api/template-message-config.js'

import Reminder from '../../common/components/Reminder'

export default {
  data() {
    return {
      loading: false, //加载中
      tips: [
        '使用该功能前需先授权公众号',
        '用户需关注公众号才能接收到模版消息',
        '如需取消授权，请前往微信公众号后台操作，操作路径为：微信公众号后台-设置与开发-公众号设置-授权管理-查看平台详情-取消授权',
      ],

      form: {
        window_notice: 0,
        wx_public_url: '',
        notice_limit: 1,
        public_info: {
          name: '',
          avatar: '',
          appid: '',
        },
      },
      rules: {},
      editData: {}, //编辑的数据
      saveLoading: false, //保存中

      // 二维码
      qrcode: '',
      qrcodeurl: '',
      // 二维授权状态
      qrstatus: -1,
      isAuthorization: false,
      close: false,
      times: '',
      isAuthPublic: false,
    }
  },
  methods: {
    // 授权公众号弹窗
    handleAuth() {
      this.isAuthPublic = true

      this.close = false
      this.isAuthorization = true
      this.onRefreshCode()
    },
    codeClose() {
      this.isAuthPublic = false

      this.close = true
    },
    // 获取二维码
    async onRefreshCode() {
      this.loading = true
      await authQrcode()
        .then((res) => {
          this.loading = false
          // 二维码
          this.qrcode = res.data.auth_key
          this.qrcodeurl = res.data.url
        })
        .catch((err) => (this.loading = false))
      this.onRefreshStatus()
    },
    // 获取二维码授权的状态
    async onRefreshStatus() {
      // console.log('yyy', this.qrcode)
      await authStatus({ auth_key: this.qrcode }).then((res) => {
        this.qrstatus = res.data.status
        // this.isAuthorization = true
      })
    },
    //获取详情
    getDetail() {
      this.loading = true
      detail()
        .then((res) => {
          const { data } = res
          // 判断是否已经授权过公众号
          if (!data.is_auth_public) {
            this.handleAuth()
          } else {
            this.codeClose()
          }
          this.editData = data
          this.initForm()

          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    //初始化表单
    initForm() {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = this.editData[key]
      })
    },
    //保存
    onSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true
          const postData = { config: this.form }
          save(postData)
            .then((res) => {
              const { msg } = res
              this.$message.success(msg)

              this.saveLoading = false
            })
            .catch(() => {
              this.saveLoading = false
            })
        }
      })
    },
  },
  watch: {
    // 轮询二维码状态
    isAuthorization() {
      this.times = setInterval(async () => {
        // 二维码状态
        this.onRefreshStatus()

        console.log(this.qrstatus, 'qrstatus')
        // 未授权 -1
        if (this.qrstatus === -1) {
        }
        // 授权失败 0
        if (this.qrstatus === 0) {
        }
        // 授权成功 1
        if (this.qrstatus === 1) {
          // 用户授权成功后清除定时器
          this.$message.success('授权成功')

          this.getDetail()
          clearInterval(this.times)
          console.log('清除定时器')
        }
        // 关闭授权窗口清除定时器
        if (this.close) {
          clearInterval(this.times)
        }
      }, 5000)
    },
  },
  created() {
    this.getDetail()
  },
  beforeDestroy() {
    // 页面销毁取消定时器
    clearInterval(this.times)
  },
  components: {
    ImageItem,
    FixedActionBar,
    Reminder,
  },
}
</script>

<style lang="scss" scoped>
.avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.qrcode {
  padding: 200px 0;
  .code {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 300px;
    font-size: 18px;
    color: black;
    .img {
      width: 140px;
      height: 140px;
    }
    .text {
      height: 80px;
      padding: 20px;
    }
  }
}
</style>
