import api from '@/base/utils/request';





export const detail = (data) => {
  return api({
    url: '/admin/wx_open/settings/templateMessageConfig',
    method: 'post',
    data
  })
}
export const save = (data) => {
  return api({
    url: '/admin/wx_open/settings/saveTemplateMessageConfig',
    method: 'post',
    data
  })
}
// 授权二维码
export const authQrcode = data => {
  return api({
      url: "/admin/portal/wx_public/authQrcode",
      method: "post",
      data
    });
}
// 查询二维码授权状态
export const authStatus = data => {
  return api({
      url: "/admin/portal/wx_public/authStatus",
      method: "post",
      data
    });
}